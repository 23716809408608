import _ from "lodash"
import React, { useEffect } from "react"
import { Paginator } from "../../../../components"
import CardResult from "../card-result"

const ListCardResults = ({
  t,
  data,
  search,
  totalPages,
  totalPosts,
  onHandleNextPage,
  pageSelect,
}) => {
  useEffect(() => {
    // Scroll to the top of the document whenever pageSelect changes
    window.scrollTo(0, 0)
  }, [pageSelect])

  if (_.isEmpty(data)) {
    return <p className="search__noResults">{t("search.noResults")}</p>
  }

  return (
    <React.Fragment>
      <div className="row">
        {_.map(data, item => (
          <div className="col-12 search__list" key={item.id}>
            <CardResult data={item} search={search} />
          </div>
        ))}
      </div>

      <div className="own-paginator">
        <Paginator
          itemsPerPage={9}
          items={totalPosts}
          onHandleNextPage={onHandleNextPage}
          pageSelect={pageSelect}
        />
      </div>
    </React.Fragment>
  )
}

export default ListCardResults
